let router = [];
export default router = [
  {
    path: "/equipment_details",
    name: "equipment_details",
    component: () => import("@/views/equipment/componetns/details.vue"),
  },
  {
    path: "/searchList",
    component: () => import("@/views/equipment/componetns/searchInfo.vue"),
    meta:{
      keepAlive:true
    }
  },
];
