import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import '@vant/touch-emulator';
import { Notify } from 'vant'
Vue.prototype.Notify =Notify
import 'vant/lib/index.css';
import '@/assets/iconfont/index.css'
import '@/flexible/flexible'

// PC端 cell滑动单元格可滑动
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

// 导入导航权限守卫
import '@/permission'

// vconsole
// import Vconsole from 'vconsole';
 
// let vconsole = new Vconsole;
 


// 引入SignalR  
// import './signalRHelper'
import signalr  from './utils/signalR'
Vue.use(signalr)

// import VueGoodTablePlugin from 'vue-good-table';

// // import the styles
// import 'vue-good-table/dist/vue-good-table.css'

// Vue.use(VueGoodTablePlugin);

import lyzTable from 'lyz-table'
Vue.use(lyzTable)


Vue.use(Vant);

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
