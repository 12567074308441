let router = [];
export default router = [
  {
    path: "/my/userDetails",
    component: () => import("@/views/my/components/myDetails.vue"),
  },
  {
    path: "/my/updateMy",
    component: () => import("@/views/my/components/updateMy.vue"),
  },
  {
    path: "/my/userList",
    component: () => import("@/views/my/components/userList.vue"),
  },
  {
    name: "/my/userList/register",
    path: "/my/userList/register",
    component: () => import("@/views/my/components/register.vue"),
  },
  {
    path: "/my/userList/info",
    component: () => import("@/views/my/components/info.vue"),
  },
  {
    path: "/my/userList/editInfo",
    component: () => import("@/views/my/components/editInfo.vue"),
  },
  {
    path:'/my/batchSet/batchList',
    component:() => import('@/views/my/components/batchset/batchList.vue')
  },
  {
    path:'/my/batchSet/batchSetting',
    component:() => import('@/views/my/components/batchset/setting.vue')
  },
  {
    path:'/my/batchSet/deviceList',
    component:() => import('@/views/my/components/batchset/deviceList.vue')
  },
  {
    path:'/my/batchUpdate',
    component:() => import('@/views/my/components/batchUpdate.vue')
  },
];
