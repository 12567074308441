import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
// import scanRouter from "./scan";
// import userListRouter from "./userList";
import equipmentRouter from "./equipment";
import mapRouter from "./map";
import myRouter from "./my";

let routesSync = [...equipmentRouter, ...myRouter, ...mapRouter];

const routes = [
  {
    path: "/",
    name: "layout",
    redirect: "/equipment",
    component: () => import("../views/layout/index.vue"),
    children: [
      {
        path: "/equipment",
        component: () => import("@/views/equipment/index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/map",
        component: () => import("@/views/map/index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/message",
        component: () => import("@/views/message/index.vue"),
      },
      {
        path: "/my",
        component: () => import("@/views/my/index.vue"),
      },
    ],
  },
  // {
  //   path: "/my/userDetails",
  //   component: () => import("@/views/my/components/myDetails.vue"),
  // },
  // {
  //   path: "/my/updateMy",
  //   component: () => import("@/views/my/components/updateMy.vue"),
  // },
  // {
  //   path: "/searchList",
  //   component: () => import("@/views/equipment/componetns/searchInfo.vue"),
  // },
  // {
  //   path: "/equipment_details",
  //   name: "equipment_details",
  //   component: () => import("@/views/equipment/details.vue"),
  // },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  {
    path: "/test",
    component: () => import("@/views/test/index.vue"),
    hidden: true,
  },
  { path: "*", redirect: "/404", hidden: true },
];

const router = new VueRouter({
  routes: [...routes, ...routesSync],
});
export default router;
