
import * as signalR from "@microsoft/signalr"

const connection = new signalR.HubConnectionBuilder()
    .withUrl("https://xfapi.wolandoo.cn/ws/notificationHub",{
        skipNegotiation:true,
        transport:signalR.HttpTransportType.WebSockets
    })
    .build();

async function start() {
    try {
        await connection.start();
        console.log("SignalR Connected.");        
    } catch (err) {
        console.log(err);
        setTimeout(start, 5000);
    }
};


// connection.on("OnDtuChanged",(id,obj)=>{
//     console.log(obj,"~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
// })
connection.onclose(async () => {
    await start();
});


// Start the connection.
start();
let test = connection;
export default {
    // install方法的第一个参数是 Vue 构造器，第二个参数是一个可选的选项对象。
    install: function(Vue) {
        Vue.prototype.signalr = connection
    }
}

