<template>
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive && $route.path == '/equipment'" />
    </keep-alive>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive && $route.path == '/map'" />
    </keep-alive>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive && $route.path == '/searchList'" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
import Vue from 'vue'
import { Notify } from 'vant';
export default {
  data() {
    return {
      screenWidth: ''
    }
  },
  beforeCreate() {
    sessionStorage.setItem('need_wait', true)
  },
  mounted() {
    Vue.prototype.$notify = Notify
    setTimeout(() => {
      Vue.prototype.signalr.invoke("JoinGroup", String(this.$store.state.userInfo.id));
      sessionStorage.setItem('need_wait', false)
    }, 1000);
  },
  
};
</script>

<style lang="scss" scoped>
::v-deep .amap-logo {
  display: none !important;
}

::v-deep .amap-copyright,
.amap-logo {
  display: none !important;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
