import router from '@/router'
import store from './store'

// 全局前置守卫
const whiteList = ['/404','/login']
router.beforeEach(async (to,from,next)=>{
    if( localStorage.getItem('token') ){
        if( to.path === '/login'){
            /* 登录了还去登录页，就让他去主页 */
            next('/')
        } else if(to.path === '/my/userList' || to.path === '/my/userList/register' ) {
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            if( userInfo.level == 2 ||  userInfo.level == 1) {
                next()
            } else {
                next('/404')
            }
        } else if(to.path == '/my/batchUpdate') {
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            if( userInfo.level == 2 ) {
                next()
            } else {
                next('/404')
            }
        } else{
            if(store.state.token?.length <10) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                store.commit('setUserInfo',userInfo)
            }
            next()
        }
    }else{
        // 未登录
        if( whiteList.includes(to.path) ){
            // 在白名单中
            next()
        }else{
            // 不在白名单中(非法访问)
            next('/login')
        }
    }
})

// 全局后置守卫
router.afterEach((to,from)=>{
    
})
