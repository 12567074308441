import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo:{},
    token:'',
    isMsg:JSON.parse(localStorage.getItem('haveMsg')) || false
  },
  getters: {

  },
  mutations: {
    setUserInfo(state,data) {
      state.userInfo = data 
      state.token = data.token 
    },
    set_isMsg(state,data) {
      state.isMsg = data
    }
    // setlevel(state,data) {
    //   state.userInfo.level = data
    // }
  },
  actions: {
  },
  modules: {
  }
})
