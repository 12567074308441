let router
export default  router = [
  {
    name: "scan",
    path: "/scan",
    component: () => import("@/views/map/components/scan.vue"),
  },
  {
    path: "/scan/scan_Info",
    component: () => import("@/views/map/components/scan_Info.vue"),
  },
  {
    path: "/scan/scan_Info/editLocations",
    component: () => import("@/views/map/components/editLocations.vue"),
  },
];
